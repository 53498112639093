import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fx-credit-card-payment-failed',
  templateUrl: './credit-card-payment-failed.component.html',
  styleUrls: ['./credit-card-payment-failed.component.scss']
})
export class CreditCardPaymentFailedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
