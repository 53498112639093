import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { CreditCardPaymentFailedComponent } from './core/pages/error/credit-card-payment-failed/credit-card-payment-failed.component';

const routes: Routes = [
  { path: '', redirectTo: "/shop", pathMatch: "full" },
  { path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) }, 
  { path: 'shop', loadChildren: () => import('./features/shop/shop.module').then(m => m.ShopModule), canActivate: [AuthGuard] },
  { path: 'shipment', loadChildren: () => import('./features/shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [AuthGuard] },
  { path: 'notification', loadChildren: () => import('./features/notification/notification.module').then(m => m.NotificationModule), canActivate: [AuthGuard] },
  { path: 'happy-pay', loadChildren: () => import('./features/happy-pay/happy-pay.module').then(m => m.HappyPayModule), canActivate: [AuthGuard] },
  { path: 'order', loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule), canActivate: [AuthGuard] },
  { path: 'product', loadChildren: () => import('./features/product/product.module').then(m => m.ProductModule), canActivate: [AuthGuard] },
  { path: 'brand', loadChildren: () => import('./features/brand/brand.module').then(m => m.BrandModule), canActivate: [AuthGuard] },
  { path: 'promotion', loadChildren: () => import('./features/promotion/promotion.module').then(m => m.PromotionModule), canActivate: [AuthGuard] },
  { path: 'voucher', loadChildren: () => import('./features/voucher/voucher.module').then(m => m.VoucherModule), canActivate: [AuthGuard] },
  { path: 'payment', loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule), canActivate: [AuthGuard] },
  { path: 'error/credit-card-payment-failed', component: CreditCardPaymentFailedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
